import React, { FC, useEffect } from "react";
import { FeatureBanner } from "./feature-banner/FeatureBanner";
import "./feature.scss";
import { OurIsland } from "./our-island/OurIsland";

export const Feature:FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <FeatureBanner/>
            <OurIsland/>
        </>
    );
};