import React, { useState, useEffect, FC } from "react";
import { Link ,NavLink} from "react-router-dom";
import { NextGenImages } from "../nextgen-image/NextGenImage";
import Logopng from "./../../assets/images/logo.png";
import Logowebp from "./../../assets/images/logo.png";
import "./header.scss";

export const Header: FC = () => {
    let [verifyUser,setVerifyUser] = useState(false);
  
    useEffect(() => {
        if(sessionStorage.getItem("token"))
    {
        setVerifyUser(true);
    }else{
        setVerifyUser(false);
    }
      },[sessionStorage.getItem("token")]);  
    console.log(verifyUser,'verifyUser')
      function submitForm(){
        sessionStorage.setItem("token", "");
        setVerifyUser(false);
      }
    return (
        <>
            <header id="header">
                <div className="container">
                    <div className="header-hold">
                        <NavLink to="/" className="logo">
                            <NextGenImages
                            src={Logowebp}
                            fallback={Logopng}
                            alt="Metavitality Logo"
                            />
                        </NavLink>
                        <input className="side-menu" type="checkbox" id="side-menu"/>
                        <label className="hamb" htmlFor="side-menu">
                            <span className="hamb-line"></span>
                        </label>
                        <nav className="nav">
                            <ul className="menu" >
                                <li>
                                    <NavLink to="/feature">Features</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/about-us">About us</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/contact">Contact us</NavLink>
                                </li>
                                {verifyUser ? (
                               <li className="btn-group"> 
                                  <NavLink to="javascript:void(0)" onClick = {() => submitForm()} className="btn btn-outline-primary">Logout</NavLink>
                                </li>
                                ) : (
                                <li className="btn-group">
                                    <NavLink to="/login" className="btn btn-outline-primary">Login</NavLink>
                                    <NavLink to="/signup" className="btn btn-primary">Sign up</NavLink>
                                </li>
                           
                                    )}
                            </ul>
                        </nav>
                    </div>
                </div>
            </header>
        </>
    );
};
