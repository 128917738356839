import React, { FC } from "react";
import "./ourMission.scss";

export const OurMission:FC = () => {
    return (
        <>
            <section id="ourmission" className="our-mission text-center">
                <div className="container">
                    <header className="heading-hold">
                        <h2>Our Mission</h2>
                        <strong className="h1 d-block">To create prosperity for a global community of entrepreners via the frictionless exchange of metaverse value.</strong>
                        <p>We want to enable investors and health & wellness professionals to reach a global marketplace by creating an interactive, digital community like no other. Here in Metavitality, the land itself is an asset, but so are the buildings and businesses that grow on them. It’s a fully-functional digital economy that can engage the healthcare community like never before. Metavitality is the future of multiple industries, and you can help build it.</p>
                    </header>
                </div>
            </section>
        </>
    );
};