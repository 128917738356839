import React, { FC } from "react";

interface Props {
    src:any,
    fallback:any,
    type?:any,
    alt:any
}
export const NextGenImages: FC<Props> = ({src, fallback, type = 'image/webp', ...delegated}) => {
    return (
      <picture>
        <source srcSet={src} type={type} />
        <img src={fallback} {...delegated} />
      </picture>
    );
};
