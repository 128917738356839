import React, { FC } from "react";
import { Link } from "react-router-dom";
import "./newsLetter.scss";

export const NewsLetter: FC = () => {
    return (
        <>
           <section className="newsletter">
               <div className="container">
                   <div className="text-hold text-center">
                        <h2>Join the Healthcare Revolution! </h2>
                        <p>Buying your first plot is just the beginning. At Metavitality, we will embrace constant
                        technological expansion, introducing new features and opportunities as soon as they become 
                        available. If you’re a health and wellness professional, this is your invitation into the virtual world.</p>
                   </div>
                   <div className="btn-group text-center">
                        <Link to="/" className="btn btn-outline-primary">Join Today</Link>
                        <Link to="/" className="btn btn-primary">Contact us</Link>
                   </div>
               </div>
           </section>
        </>
    );
};
