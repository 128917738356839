import React, { FC } from "react";
import { Link } from "react-router-dom";
import "./leaderShip.scss";
import RyanWalia from "./../../../assets/images/ryan-walia.jpg";
import FlaviaTorres from "./../../../assets/images/flavia-torres.jpg";
import ElisabethAllaire from "./../../../assets/images/elisabeth-allaire.jpg";
import JeremyCarpenter from "./../../../assets/images/jeremy-carpenter.jpg";

export const LeaderShip:FC = () => {
    return (
        <>
            <section id="leadership" className="leadership">
                <div className="container">
                    <header className="heading-hold text-center">
                        <h2>Leadership at Metavitality</h2>
                        <p>Big ideas require big personalities to bring them to life. Here at Metavitality, we’ve assembled some of the best minds in multiple industries. And while every business owner and virtual landlord is just as integral to our shared success, we’ve already run out of room to list all of you.</p>
                    </header>
                    <div className="card">
                        <figure className="card-hold">
                            <picture className="card-image">
                                <img src={RyanWalia} alt="Body Mind Island" loading="lazy" />
                            </picture>
                            <figcaption className="card-text">
                                <h3>Ryan Walia</h3>
                                <p>Chief Executive Officer</p>
                                <Link to="/about-us" className="card-icon">
                                    <span className="icon-hold">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                            <path d="M14.25 0H3.75C1.67925 0 0 1.67925 0 3.75V14.25C0 16.3207 1.67925 18 3.75 18H14.25C16.3215 18 18 16.3207 18 14.25V3.75C18 1.67925 16.3215 0 14.25 0ZM6 14.25H3.75V6H6V14.25ZM4.875 5.049C4.1505 5.049 3.5625 4.4565 3.5625 3.726C3.5625 2.9955 4.1505 2.403 4.875 2.403C5.5995 2.403 6.1875 2.9955 6.1875 3.726C6.1875 4.4565 5.60025 5.049 4.875 5.049ZM15 14.25H12.75V10.047C12.75 7.521 9.75 7.71225 9.75 10.047V14.25H7.5V6H9.75V7.32375C10.797 5.38425 15 5.241 15 9.18075V14.25Z" fill="#00FFFF"/>
                                        </svg>
                                    </span>
                                </Link>
                            </figcaption>
                        </figure>
                        <figure className="card-hold">
                            <picture className="card-image">
                                <img src={FlaviaTorres} alt="Flavia Torres" loading="lazy" />
                            </picture>
                            <figcaption className="card-text">
                                <h3>Flavia Torres</h3>
                                <p>Chief Operating Officer</p>
                                <Link to="/about-us" className="card-icon">
                                    <span className="icon-hold">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                            <path d="M14.25 0H3.75C1.67925 0 0 1.67925 0 3.75V14.25C0 16.3207 1.67925 18 3.75 18H14.25C16.3215 18 18 16.3207 18 14.25V3.75C18 1.67925 16.3215 0 14.25 0ZM6 14.25H3.75V6H6V14.25ZM4.875 5.049C4.1505 5.049 3.5625 4.4565 3.5625 3.726C3.5625 2.9955 4.1505 2.403 4.875 2.403C5.5995 2.403 6.1875 2.9955 6.1875 3.726C6.1875 4.4565 5.60025 5.049 4.875 5.049ZM15 14.25H12.75V10.047C12.75 7.521 9.75 7.71225 9.75 10.047V14.25H7.5V6H9.75V7.32375C10.797 5.38425 15 5.241 15 9.18075V14.25Z" fill="#00FFFF"/>
                                        </svg>
                                    </span>
                                </Link>
                            </figcaption>
                        </figure>
                        <figure className="card-hold">
                            <picture className="card-image">
                                <img src={ElisabethAllaire} alt="Elisabeth Allaire" loading="lazy" />
                            </picture>
                            <figcaption className="card-text">
                                <h3>Elisabeth Allaire</h3>
                                <p>Head of Global Policy</p>
                                <Link to="/about-us" className="card-icon">
                                    <span className="icon-hold">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                            <path d="M14.25 0H3.75C1.67925 0 0 1.67925 0 3.75V14.25C0 16.3207 1.67925 18 3.75 18H14.25C16.3215 18 18 16.3207 18 14.25V3.75C18 1.67925 16.3215 0 14.25 0ZM6 14.25H3.75V6H6V14.25ZM4.875 5.049C4.1505 5.049 3.5625 4.4565 3.5625 3.726C3.5625 2.9955 4.1505 2.403 4.875 2.403C5.5995 2.403 6.1875 2.9955 6.1875 3.726C6.1875 4.4565 5.60025 5.049 4.875 5.049ZM15 14.25H12.75V10.047C12.75 7.521 9.75 7.71225 9.75 10.047V14.25H7.5V6H9.75V7.32375C10.797 5.38425 15 5.241 15 9.18075V14.25Z" fill="#00FFFF"/>
                                        </svg>
                                    </span>
                                </Link>
                            </figcaption>
                        </figure>
                        <figure className="card-hold">
                            <picture className="card-image">
                                <img src={JeremyCarpenter} alt="Jeremy Carpenter" loading="lazy" />
                            </picture>
                            <figcaption className="card-text">
                                <h3>Jeremy Carpenter</h3>
                                <p>Chief Strategy Officer</p>
                                <Link to="/about-us" className="card-icon">
                                    <span className="icon-hold">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                            <path d="M14.25 0H3.75C1.67925 0 0 1.67925 0 3.75V14.25C0 16.3207 1.67925 18 3.75 18H14.25C16.3215 18 18 16.3207 18 14.25V3.75C18 1.67925 16.3215 0 14.25 0ZM6 14.25H3.75V6H6V14.25ZM4.875 5.049C4.1505 5.049 3.5625 4.4565 3.5625 3.726C3.5625 2.9955 4.1505 2.403 4.875 2.403C5.5995 2.403 6.1875 2.9955 6.1875 3.726C6.1875 4.4565 5.60025 5.049 4.875 5.049ZM15 14.25H12.75V10.047C12.75 7.521 9.75 7.71225 9.75 10.047V14.25H7.5V6H9.75V7.32375C10.797 5.38425 15 5.241 15 9.18075V14.25Z" fill="#00FFFF"/>
                                        </svg>
                                    </span>
                                </Link>
                            </figcaption>
                        </figure>
                    </div>
                </div>
            </section>
        </>
    );
};