import React, { FC, useEffect } from "react";
import "./contactUs.scss";

export const ContactUs: FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <section className="contact-section container">
                <h1>Talk with us!</h1>
                <div className="ask-bar">
                    <p>To talk with the team, get updates or ask technical questions!</p>
                    <button>Join Discord</button>
                </div>
                <div className="contact-info">
                    <div className="info-box">
                        <b>Email for Business</b>
                        <a href="">business@metavitality.com</a>
                    </div>
                    <div className="info-box">
                        <b>Phone Number for Business</b>
                        <a href="">+92 321 6547890</a>
                    </div>
                    <div className="info-box">
                        <b>Email for Press & Media</b>
                        <a href="">media@metavitality.com</a>
                    </div>
                </div>
                <p className='enquiry-text'>For enquiries or to join fill out the form below and we’ll get back to you shortly. </p>
                <div className="contact-form">
                    <form action="">
                        <div className='form-fields'>
                            <div className="fields">
                                <label htmlFor="">Name</label>
                                <input type="text" placeholder='Full Name' />
                            </div>
                            <div className="fields">
                                <label htmlFor="">Email</label>
                                <input type="email" placeholder='email@example.com'/>
                            </div>
                        </div>
                        <div className='form-fields'>
                            <div className="fields">
                                <label htmlFor="">Phone Number</label>
                                <input type="tel" placeholder='+92 321 6547890'/>
                            </div>
                            <div className="fields">
                                <label htmlFor="">Subject</label>
                                <input type="text" placeholder='subject'/>
                            </div>
                        </div>
                        <div className="fields">
                            <label htmlFor="">Subject</label>
                            <textarea name="" id="" placeholder='Please write your message here....'/>
                        </div>
                            <button className="send-btn btn btn-primary">Send Message</button>
                    </form>
                </div>
            </section>
        </>
    );
}