import React, { FC } from "react";
import { Link } from "react-router-dom";
import "./footer.scss";
import { NewsLetter } from "./news-letter/NewsLetter";
import FooterLogo from "./../../assets/images/footer-logo.png";
import Facebook from "./../../assets/images/facebook-white.png";
import Twitter from "./../../assets/images/twitter.png";
import LinkedIn from "./../../assets/images/linkedin.png";
import Youtube from "./../../assets/images/youtube.png";
import { NavLink } from "react-router-dom";

export const Footer: FC = () => {
    return (
        <>
            <NewsLetter/>
            <footer id="footer">
                <div className="container">
                    <div className="top-footer">
                        <ul className="footer-nav left-nav">
                            <li>
                                <NavLink to="/">Home</NavLink>
                            </li>
                            <li>
                                <NavLink to="/about-us">About us</NavLink>
                            </li>
                            <li>
                                <NavLink to="/feature">Features</NavLink>
                            </li>
                        </ul>
                        <strong className="footer-logo">
                            <img src={FooterLogo} alt="Metavitality" loading="lazy" />
                        </strong>
                        <ul className="footer-nav right-nav">
                            <li>
                                <NavLink to="/contact">Contact us</NavLink>
                            </li>
                            <li>
                                <NavLink to="/login">Login</NavLink>
                            </li>
                            <li>
                                <NavLink to="/signup">Sign up</NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="bottom-footer text-center">
                        <ul className="social-icon">
                            <li>
                                <Link to="/" className="icon-hold">
                                    <span className="icon-wrap">
                                        <img src={Facebook} alt="Facebook" />
                                    </span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/" className="icon-hold">
                                    <span className="icon-wrap">
                                        <img src={Twitter} alt="Twitter" />
                                    </span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/" className="icon-hold">
                                    <span className="icon-wrap">
                                        <img src={LinkedIn} alt="LinkedIn" />
                                    </span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/" className="icon-hold">
                                    <span className="icon-wrap">
                                        <img src={Youtube} alt="Youtube" />
                                    </span>
                                </Link>
                            </li>
                        </ul>
                        <p>© MetaVitality 2022. All Rights Reserved</p>
                    </div>
                </div>
            </footer>
        </>
    );
};
