import React, { FC } from "react";
import "./islandFeature.scss";
import CommunityCenter from "./../../../assets/images/community-center.png";
import Beach from "./../../../assets/images/beach.png";
import Forest from "./../../../assets/images/forest.png";
import HillArea from "./../../../assets/images/hill-area.png";
import Desert from "./../../../assets/images/desert.png";
import ConcertArea from "./../../../assets/images/concert-area.png";
import CommercialArea from "./../../../assets/images/commercial-area.png";
import FieldArea from "./../../../assets/images/field-area.png";


export const IslandFeature:FC = () => {
    return (
        <>
            <section className="island-feature">
                <div className="container">
                    <div className="heading-head text-center">
                        <h2>
                            Mind & Body Island Features
                        </h2>
                        <p>Body and Mind Island will welcome professionals from backgrounds like Homeopathy, Spirituality, Life Coaching, Psychology, Meditation, Ayurveda, and more.</p>
                    </div>
                    <div className="card">
                        <figure className="card-hold">
                            <picture className="card-image">
                                <img src={CommunityCenter} alt="Community Center" loading="lazy" />
                            </picture>
                            <figcaption className="card-text text-center">
                                <h4>Community Center</h4>
                            </figcaption>
                        </figure>
                        <figure className="card-hold">
                            <picture className="card-image">
                                <img src={Beach} alt="Beach" loading="lazy" />
                            </picture>
                            <figcaption className="card-text text-center">
                                <h4>Beach</h4>
                            </figcaption>
                        </figure>
                        <figure className="card-hold">
                            <picture className="card-image">
                                <img src={Forest} alt="Forest" loading="lazy" />
                            </picture>
                            <figcaption className="card-text text-center">
                                <h4>Forest</h4>
                            </figcaption>
                        </figure>
                        <figure className="card-hold">
                            <picture className="card-image">
                                <img src={HillArea} alt="Hill Area" loading="lazy" />
                            </picture>
                            <figcaption className="card-text text-center">
                                <h4>Hill Area</h4>
                            </figcaption>
                        </figure>
                        <figure className="card-hold">
                            <picture className="card-image">
                                <img src={Desert} alt="Desert" loading="lazy" />
                            </picture>
                            <figcaption className="card-text text-center">
                                <h4>Desert</h4>
                            </figcaption>
                        </figure>
                        <figure className="card-hold">
                            <picture className="card-image">
                                <img src={CommercialArea} alt="Commercial Area" loading="lazy" />
                            </picture>
                            <figcaption className="card-text text-center">
                                <h4>Commercial Area</h4>
                            </figcaption>
                        </figure>
                        <figure className="card-hold">
                            <picture className="card-image">
                                <img src={ConcertArea} alt="Concert Area" loading="lazy" />
                            </picture>
                            <figcaption className="card-text text-center">
                                <h4>Concert Area</h4>
                            </figcaption>
                        </figure>
                        <figure className="card-hold">
                            <picture className="card-image">
                                <img src={FieldArea} alt="Field Area" loading="lazy" />
                            </picture>
                            <figcaption className="card-text text-center">
                                <h4>Field Area</h4>
                            </figcaption>
                        </figure>
                    </div>
                </div>
            </section>
        </>
    );
};