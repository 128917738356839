import React, { FC, Suspense } from "react";
import { HashRouter as Router } from "react-router-dom";
import { Footer } from "../component/footer/footer";
import { Header } from "../component/header/Header";
import { MainRouter } from "../routes/MainRouter";

export const Main: FC = () => {
  return (
    <>
      <Router>
        <Header />
        <Suspense fallback={<div>Loading... </div>}>
          <main id="main">
            <MainRouter />
          </main>
        </Suspense>
        <Footer />
      </Router>
    </>
  );
};
