import React,{useEffect,useState} from "react";
import axios from "axios";
import "./emailVerification.scss";

export const Verification = () => {
    const [message,setMessage] = useState("");
    const [className,setClassName] = useState("");
    const [isMessage,setIsMessage] = useState(false);

    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const hash = params.get('hash');
        console.log(process.env.REACT_APP_BACKE_END_URL);
        axios.post(process.env.REACT_APP_BACKE_END_URL+'/api/users/account-verification', {
            hash:hash,
          })
          .then(function (response) {
              console.log(response);
              setIsMessage(true);
            setMessage('Email Verified successfully');
            setClassName('success-text-color');
            
          })
          .catch(function (error) {
              console.log(error);
            setMessage('Link Expired, Please Try to Login');
            setClassName('error-text-color');
            setIsMessage(true);
             
          });
    }, []);
    return (
        <div>
          {isMessage?<div  className={className}>{message}</div>:''}
        </div>
      )
};