import React, { FC, useEffect, useRef, useState } from "react";
import { HeroBanner } from "../../component/hero-banner/HeroBanner";
import { BuildWorld } from "./build-world/BuildWorld";
import "./home.scss";
import { IslandSlider } from "./world-metavitality/WorldMetavitality";
import Play from "./../../assets/images/play-button.png";

export const Home:FC = () => {
    const [playing, setPlaying] = useState(false);
    const videoRef = useRef<any>('');
    const videoHandler = (control:any) => {
        if (control === "play") {
        videoRef.current.play();
        setPlaying(true);
        } else if (control === "pause") {
        videoRef.current.pause();
        setPlaying(false);
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <section className="hero-banner text-center">
                <div className="container">
                    <div className="hero-banner-hold">
                        <HeroBanner/>
                        <div className="video-hold d-block video-container">
                            <video width="100%" controls loop playsInline>
                                <source src="http://metavitality-cdn.jupitereats.com/banner-video.mp4" type='video/mp4'/>
                            </video>
                            {/* <div className="controlsContainer">
                                {playing ? (
                                <img
                                    onClick={() => videoHandler("pause")}
                                    className="controlsIcon--small"
                                    alt="Paly Icon"
                                    src={Play}
                                />
                                ) : (
                                <div onClick={() => videoHandler("play")} className="overlay-div"></div>
                                )}
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
            <BuildWorld/>
            <IslandSlider/>
        </>
    );
};