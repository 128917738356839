import React, { FC } from "react";
import "./aboutUsBanner.scss";

export const AboutUsBanner:FC = () => {
    return (
        <>
            <section className="about-us-banner text-center">
                <div className="container">
                    <div className="about-us-banner-hold">
                    <h1>
                        About Metavitality
                    </h1>
                    <header className="heading-head">
                        <strong className="h3 d-block">A Revolution on Three Fronts: Virtual Property, Health & Fitness, and Metaverse Economics.</strong>
                        <p>Metavitality is a comprehensive digital space comprised of unique, themed “islands.” These islands and the plots of land that make them up all represent virtual land that investors can purchase. From their new plot, owners can create fully-functional digital businesses focused on improving the health and wellness of customers all over the world. Not only can you invest in the Metaverse, you be part of a movement that improves the minds, bodies, and spirits of countless people in our global community.</p>
                    </header>
                    </div>
                </div>
            </section>
        </>
    );
};