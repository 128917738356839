import React, { FC, useEffect } from "react";
import { IslandFeature } from "./island-feature/IslandFeature";
import { SingleFeatureBanner } from "./single-feature-banner/SingleFeatureBanner";
import "./singleFeature.scss";

export const SingleFeatute:FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <SingleFeatureBanner/>
            <IslandFeature/>
        </>
    );
};