import React, { FC } from "react";
import "./heroBanner.scss";
import BannerImage from "./../../assets/images/metavitality.png";

export const HeroBanner:FC = () => {
    return (
        <>
            <h1>
                Join an Ever-Expanding World of Health and Fitness on 
                <span className="headin-img inline-block">
                    <img src={BannerImage} alt="Metavitality"/>
                </span>
                Island
            </h1>
            <header className="heading-head">
                <strong className="h3 d-block">Buy Virtual Land. Offer Your Services. Enjoy Endless Opportunities. </strong>
                <p>
                    Metavitality is a revolutionary new digital space for people throughout the health and fitness
                    <br/> industry. Don’t just purchase a plot of virtual land in the Metaverse; turn that plot into a fully-
                    <br/>functional business focused on improving the minds, bodies, and spirits of countless potential 
                    <br/>clients. Interact, engage, and grow your community in ways that are only possible <br/> in a digital world.</p>
            </header>
        </>
    );
};