import React, { FC } from "react";
// import { useRoutes } from "react-router-dom";
import { AboutUs } from "../pages/about-us/AboutUs";
import { Feature } from "../pages/feature/feature";
import { Home } from "../pages/home/Home";
import { Login } from "../pages/login/Login";
import { SignUp } from "../pages/signup/SignUp";
import { ContactUs } from "../pages/contact-us/ContactUs";
import { SingleFeatute } from "../pages/single-feature/SingleFeature";
import { ForgotPassword } from "../component/forgot-password/ForgotPassword";
import { UpdateForgotPassword } from "../component/update-forgot-password/UpdateForgotPassword";
import { Verification } from "../pages/email-verification/emailVerification";

import { Route, Routes } from "react-router-dom";

export const MainRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="feature" element={<Feature />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/single-feature" element={<SingleFeatute />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route
        path="/update-forgot-password"
        element={<UpdateForgotPassword />}
      />
      <Route path="/email-verification" element={<Verification />} />
    </Routes>
  );
  // let routes = useRoutes([
  //     { path: "/", element: <Home /> },
  //     { path: "/feature", element: <Feature /> },
  //     { path: "/about-us", element: <AboutUs /> },
  //     { path: "/single-feature", element: <SingleFeatute /> },
  //     { path: "/login", element: <Login /> },
  //     { path: "/signup", element: <SignUp /> },
  //     { path: "/contact", element: <ContactUs /> },
  //     { path: "/forgot-password", element: <ForgotPassword /> },
  //     { path: "/update-forgot-password", element: <UpdateForgotPassword /> },
  // ]);
  // return routes;
};
