import React, { useState,FC } from "react";
import "./updateForgotPassword.scss";
import crossIcon from "../../assets/images/cross-icon.png";
import {Link} from "react-router-dom";
import axios from "axios";


export const UpdateForgotPassword:FC = () => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [passwordError,setPasswordError] = useState("");
    const [confirmPasswordError,setConfirmPasswordError] = useState("");
    const [hashError,setHashError] = useState("");
    const [message,setMessage] = useState("");
    const [className,setClassName] = useState("");
    const [isMessage,setIsMessage] = useState(false);
    const [validation, setValidation] = useState(false);

    function submitForm(){
        const search = window.location.hash;
        const params =  search.split('hash=');
        const hash =params[1];
        if(!password)
        {
            setPasswordError('Password is required');
        }else{
            setPasswordError('');
        }
        if(!hash)
        {
            setHashError('Invalid Hash');
        }else{
            setHashError('');
        }

        if(password != confirmPassword)
        {
            setConfirmPasswordError('Confirm Password and Password must match');
        }else{
            setConfirmPasswordError('');
        }

        if(!hash || !password || password !== confirmPassword )
            {
                setValidation(true);
                setClassName('error-text-color');
                return false;
            }else{
                setValidation(false);
        axios.post(process.env.REACT_APP_BACKE_END_URL+'/api/users/update-forget-password', {
            password: password,
            confirm_password:confirmPassword,
            hash:hash,
          })
          .then(function (response) {
            setMessage(response.data.message);
            setClassName('success-text-color');
            setIsMessage(true);
          })
          .catch(function (error) {
              console.log(error);
              setMessage(error.response.data.message);
                setClassName('error-text-color');
                setIsMessage(true);
          });
        }
      }
    return (
        <>
            <div className="popup flex items-center flex-text-center active">
                <div className="popup-holder relative">
                    <h2>Update Password</h2>
                    <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.</p>
                    <div className="row">
                        <label htmlFor="Password">Password</label>
                        <input type="password" id="password"  onChange={(e) => setPassword(e.target.value)} placeholder="Enter your Password"/>
                        {validation?<div  className={className}>{passwordError}</div>:''}
                    </div>
                    <div className="row">
                        <label htmlFor="confirm_password">Confirm Password</label>
                        <input type="password" id="confirm_password"  onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirm Password"/>
                        {validation?<div  className={className}>{confirmPasswordError}</div>:''}
                    </div>
                    {validation?<div  className={className}>{hashError}</div>:''}
                    {isMessage?<div  className={className}>{message}</div>:''}
                    <button onClick = {() => submitForm()} className="btn btn-primary flex w-auto">Update Password</button>
                    <span className="cross-icon">
                        <img src={crossIcon} alt="cross-icon"/>
                    </span>
                </div>
            </div>
        </>
    );
};