import React, { FC } from "react";
import { Link } from "react-router-dom";
import "./partners.scss";
import Polkalokar from "./../../../assets/images/polkalokr.png";
import Polygon from "./../../../assets/images/polygon.png";
import Polygen from "./../../../assets/images/polygen.png";
import NftyPlay from "./../../../assets/images/nft-play.png";
import Router from "./../../../assets/images/router.png";
import Crypto from "./../../../assets/images/crypto.png";
import Dafi from "./../../../assets/images/dafi.png";
import GameRich from "./../../../assets/images/game-rich.png";


export const Partners:FC = () => {
    return (
        <>
            <section id="partners" className="partners">
                <div className="container">
                    <header className="heading-hold text-center">
                        <h2>Meet our Partners</h2>
                        <p>Great ideas can’t stay secret for long, and Metavitality is proud to be supported by some of the most important names in the digital world.  </p>
                    </header>
                    <div className="image-container">
                        <Link to="/about-us" className="image-hold flex items-center flex-text-center">
                            <picture className="image-wrap">
                                <img src={Polkalokar} alt="Polkalokar" />
                            </picture>
                        </Link>
                        <Link to="/about-us" className="image-hold flex items-center flex-text-center">
                            <picture className="image-wrap">
                                <img src={Polygon} alt="Polygon" />
                            </picture>
                        </Link>
                        <Link to="/about-us" className="image-hold flex items-center flex-text-center">
                            <picture className="image-wrap">
                                <img src={Polygen} alt="Polygen" />
                            </picture>
                        </Link>
                        <Link to="/about-us" className="image-hold flex items-center flex-text-center">
                            <picture className="image-wrap">
                                <img src={NftyPlay} alt="Nfty Play" />
                            </picture>
                        </Link>
                        <Link to="/about-us" className="image-hold flex items-center flex-text-center">
                            <picture className="image-wrap">
                                <img src={Router} alt="Router" />
                            </picture>
                        </Link>
                        <Link to="/about-us" className="image-hold flex items-center flex-text-center">
                            <picture className="image-wrap">
                                <img src={Crypto} alt="Crypto League Gaming" />
                            </picture>
                        </Link>
                        <Link to="/about-us" className="image-hold flex items-center flex-text-center">
                            <picture className="image-wrap">
                                <img src={Dafi} alt="Dafi" />
                            </picture>
                        </Link>
                        <Link to="/about-us" className="image-hold flex items-center flex-text-center">
                            <picture className="image-wrap">
                                <img src={GameRich} alt="Game Rich" />
                            </picture>
                        </Link>
                    </div>
                </div>
            </section>
        </>
    );
};