import React, { FC } from "react";
import "./singleFeatureBanner.scss";
import BodyMindIsland from "./../../../assets/images/bodymind.png";

export const SingleFeatureBanner:FC = () => {
    return (
        <>
            <section className="single-feature-banner">
                <div className="container">
                    <div className="single-feature-banner-hold">
                        <aside className="map-option">
                            <h3>Map Options</h3>
                            <ul className="map-option-hold">
                                <strong className="h3 d-block">Areas:</strong>
                                <li>
                                    <input className="fake-radio" type="radio" value="1" name="gender" id="1" />
                                    <label htmlFor="1" className="text-hold">
                                        <span>(383) Total</span>
                                        <span>1 (1x1)</span>
                                    </label>
                                </li>
                                <li>
                                    <input className="fake-radio" type="radio" value="2" name="gender" id="2" />
                                    <label htmlFor="2" className="text-hold">
                                        <span>(155) Total</span>
                                        <span>2 (1x2)</span>
                                    </label>
                                </li>
                                <li>
                                    <input className="fake-radio" type="radio" value="3" name="gender" id="3" />
                                    <label htmlFor="3" className="text-hold">
                                        <span>(64) Total</span>
                                        <span>3 (1x3)</span>
                                    </label>
                                </li>
                                <li>
                                    <input className="fake-radio" type="radio" value="4" name="gender" id="4" />
                                    <label htmlFor="4" className="text-hold">
                                        <span>(26) Total</span>
                                        <span>4 (2x2)</span>
                                    </label>
                                </li>
                                <li>
                                    <input className="fake-radio" type="radio" value="5" name="gender" id="5" />
                                    <label htmlFor="5" className="text-hold">
                                        <span>(21) Total</span>
                                        <span>6 (2x3)</span>
                                    </label>
                                </li>
                                <li>
                                    <input className="fake-radio" type="radio" value="6" name="gender" id="6" />
                                    <label htmlFor="6" className="text-hold">
                                        <span>(11) Total</span>
                                        <span>9 (3x3)</span>
                                    </label>
                                </li>
                                <li>
                                    <input className="fake-radio" type="radio" value="7" name="gender" id="7" />
                                    <label htmlFor="7" className="text-hold">
                                        <span>(03) Total</span>
                                        <span>15 (3x5)</span>
                                    </label>
                                </li>
                                <li>
                                    <input className="fake-radio" type="radio" value="8" name="gender" id="8" />
                                    <label htmlFor="8" className="text-hold">
                                        <span>(01) Total</span>
                                        <span>25 (5x5)</span>
                                    </label>
                                </li>
                            </ul>
                        </aside>
                        <div className="content relative">
                            <img src={BodyMindIsland} alt="Body And Mind Island" />
                            <div className="overlay">
                                <h2>Mind & Body Island</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};