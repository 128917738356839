import React, { FC, useEffect } from "react";
import { HashLink } from 'react-router-hash-link';
import { AboutUsBanner } from "./about-us-banner/AboutUsBanner";
import "./aboutUs.scss";
import { OurMission } from "./our-mission/OurMission";
import AboutUsSmoothScroll from "./../../assets/images/about-us-smooth.jpg";
import { LeaderShip } from "./leadership/LeaderShip";
import { Partners } from "./partners/Partners";

export const AboutUs:FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <AboutUsBanner/>
            <section className="smooth-scroll">
                <div className="container">
                    <ul className="smooth-scroll-nav flex item-center flex-text-center">
                        <li>
                            <HashLink smooth to={'/about-us#ourmission'}>
                                <h4>Our Mission</h4>
                            </HashLink>
                            {/* <a href="#ourmission">
                                <h4>Our Mission</h4>
                            </a> */}
                        </li>
                        <li>
                            <HashLink smooth to={'/about-us#leadership'}>
                            <h4>Leadership</h4>
                            </HashLink>
                            {/* <a href="#leadership">
                                <h4>Leadership</h4>
                            </a> */}
                        </li>
                        <li>
                            <HashLink smooth to={'/about-us#partners'}>
                            <h4>Partners</h4>
                            </HashLink>
                            {/* <a href="#partners">
                                <h4>Partners</h4>
                            </a> */}
                        </li>
                    </ul>
                    <picture className="image-hold">
                        <img src={AboutUsSmoothScroll} alt="About Us" />
                    </picture>
                </div>
            </section>
            <OurMission/>
            <LeaderShip/>
            <Partners/>
        </>
    );
};