import React from "react";
import ReactDOM from "react-dom/client";
import { Main } from "./layout/Main";
import reportWebVitals from "./reportWebVitals";
import "./index.scss";
const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement as Element);
root.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>
);

reportWebVitals();
