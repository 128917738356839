import React, { FC, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BodyMindIsland from "./../../../assets/images/body-mind-island.png";
import CosmeticIsland from "./../../../assets/images/cosmetic-island.png";
import Womenland from "./../../../assets/images/womenland.png";
import FitnesIsland from "./../../../assets/images/fitness-island.png";
import ArtMusicIsland from "./../../../assets/images/art-music-island.png";
import NutritionIsland from "./../../../assets/images/nutrition-island.png";
import SceneriesLandscapesIslanden from "./../../../assets/images/scen-landsc-island.png";
import "./worldMetavitality.scss";
import { ModalBox } from "../../../component/modal-box/ModalBox";


export const IslandSlider: FC = () => {
    const [sliderRef, setSliderRef] = useState<any>(null)
    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 2200,
                settings: {
                  slidesToShow: 7,
                  slidesToScroll: 1
                }
            },
            {
                breakpoint: 2000,
                settings: {
                  slidesToShow: 6,
                  slidesToScroll: 1
                }
            },
            {
                breakpoint: 1800,
                settings: {
                  slidesToShow: 5,
                  slidesToScroll: 1
                }
            },
            {
                breakpoint: 1600,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 1
                }
              },
            {
              breakpoint: 1440,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1
              }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    const [open, setOpen] = useState<boolean>(false)
    const handleOpen = () => {
        setOpen(true)
    }
  
    return (
        <>
            <section className="slider-section">
                <div className="container">
                    <div className="heading-head">
                        <div className="text-hold">
                            <h2>
                                <span className="d-block">The World of Metavitality.</span>
                                <span>Where Will You Plant Your Seed?
                                </span>
                            </h2>
                            <p>You can purchase a plot on any island in the Metavitality universe using Ethereum. Once your virtual land is established, you can immediately begin renting for profit or offering services to a global community of clients. </p>
                        </div>
                        <div className="slider-icon controls">
                            <span className="left-arrow icon-hold" onClick={sliderRef?.slickPrev}>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11 22C4.93081 22 -4.31065e-07 17.0692 -9.61651e-07 11C-1.49224e-06 4.93081 4.9308 3.39959e-06 11 2.869e-06C17.0692 2.33841e-06 22 4.93081 22 11C22 17.0692 17.0692 22 11 22ZM11 21.0001C16.5286 21.0001 21 16.5287 21 11.0001C21 5.47152 16.5286 1.00009 11 1.00009C5.47143 1.00009 1 5.47152 1 11.0001C1 16.5287 5.47143 21.0001 11 21.0001ZM12.6875 14.2501L12.6875 14.2499C12.4948 14.2238 12.3349 14.0876 12.2779 13.9015C12.2209 13.7152 12.277 13.5128 12.422 13.3829L14.4689 11.5L6.27339 11.5C6.25769 11.5007 6.24217 11.5007 6.22647 11.5C5.95077 11.4869 5.73786 11.2526 5.75076 10.9765C5.76366 10.7003 5.99769 10.487 6.27339 10.4999L14.4685 10.4999L12.4215 8.62488L12.4217 8.62488C12.3233 8.53577 12.2646 8.41109 12.2579 8.27838C12.2515 8.14567 12.2979 8.01575 12.387 7.9174C12.4761 7.81888 12.6006 7.75993 12.7331 7.75348C12.8657 7.74703 12.9952 7.79341 13.0934 7.8827L16.0855 10.6328L16.0857 10.6328C16.1905 10.7275 16.2505 10.8625 16.2505 11.0039C16.2505 11.1453 16.1905 11.2803 16.0857 11.375L13.0936 14.1172L13.0934 14.1172C12.9961 14.2075 12.8667 14.2553 12.7342 14.2499C12.7185 14.2508 12.7028 14.2508 12.6873 14.2499L12.6875 14.2501Z" fill="inherit" />
                                </svg>
                            </span>
                            <span className="right-arrow icon-hold" onClick={sliderRef?.slickNext}>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11 22C4.93081 22 -4.31065e-07 17.0692 -9.61651e-07 11C-1.49224e-06 4.93081 4.9308 3.39959e-06 11 2.869e-06C17.0692 2.33841e-06 22 4.93081 22 11C22 17.0692 17.0692 22 11 22ZM11 21.0001C16.5286 21.0001 21 16.5287 21 11.0001C21 5.47152 16.5286 1.00009 11 1.00009C5.47143 1.00009 1 5.47152 1 11.0001C1 16.5287 5.47143 21.0001 11 21.0001ZM12.6875 14.2501L12.6875 14.2499C12.4948 14.2238 12.3349 14.0876 12.2779 13.9015C12.2209 13.7152 12.277 13.5128 12.422 13.3829L14.4689 11.5L6.27339 11.5C6.25769 11.5007 6.24217 11.5007 6.22647 11.5C5.95077 11.4869 5.73786 11.2526 5.75076 10.9765C5.76366 10.7003 5.99769 10.487 6.27339 10.4999L14.4685 10.4999L12.4215 8.62488L12.4217 8.62488C12.3233 8.53577 12.2646 8.41109 12.2579 8.27838C12.2515 8.14567 12.2979 8.01575 12.387 7.9174C12.4761 7.81888 12.6006 7.75993 12.7331 7.75348C12.8657 7.74703 12.9952 7.79341 13.0934 7.8827L16.0855 10.6328L16.0857 10.6328C16.1905 10.7275 16.2505 10.8625 16.2505 11.0039C16.2505 11.1453 16.1905 11.2803 16.0857 11.375L13.0936 14.1172L13.0934 14.1172C12.9961 14.2075 12.8667 14.2553 12.7342 14.2499C12.7185 14.2508 12.7028 14.2508 12.6873 14.2499L12.6875 14.2501Z" fill="inherit" />
                                </svg>
                            </span>
                        </div>
                    </div>

                </div>
                <div className="card">
                        <Slider {...settings} ref={setSliderRef} >
                        <figure className="card-hold">
                                <picture className="card-image">
                                    <img src={BodyMindIsland} alt="Body Mind Island" />
                                </picture>
                                <figcaption className="card-text">
                                    <div className="text-hold">
                                        <h4>Body & Mind Island</h4>
                                        <p>Body and Mind Island will welcome professionals from backgrounds like Homeopathy, Spirituality, Life Coaching, Psychology, Meditation, Ayurveda...</p>
                                    </div>
                                    <button onClick={handleOpen} className="card-icon">
                                        <span className="icon-hold"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11 22C4.93081 22 -4.31065e-07 17.0692 -9.61651e-07 11C-1.49224e-06 4.93081 4.9308 3.39959e-06 11 2.869e-06C17.0692 2.33841e-06 22 4.93081 22 11C22 17.0692 17.0692 22 11 22ZM11 21.0001C16.5286 21.0001 21 16.5287 21 11.0001C21 5.47152 16.5286 1.00009 11 1.00009C5.47143 1.00009 1 5.47152 1 11.0001C1 16.5287 5.47143 21.0001 11 21.0001ZM12.6875 14.2501L12.6875 14.2499C12.4948 14.2238 12.3349 14.0876 12.2779 13.9015C12.2209 13.7152 12.277 13.5128 12.422 13.3829L14.4689 11.5L6.27339 11.5C6.25769 11.5007 6.24217 11.5007 6.22647 11.5C5.95077 11.4869 5.73786 11.2526 5.75076 10.9765C5.76366 10.7003 5.99769 10.487 6.27339 10.4999L14.4685 10.4999L12.4215 8.62488L12.4217 8.62488C12.3233 8.53577 12.2646 8.41109 12.2579 8.27838C12.2515 8.14567 12.2979 8.01575 12.387 7.9174C12.4761 7.81888 12.6006 7.75993 12.7331 7.75348C12.8657 7.74703 12.9952 7.79341 13.0934 7.8827L16.0855 10.6328L16.0857 10.6328C16.1905 10.7275 16.2505 10.8625 16.2505 11.0039C16.2505 11.1453 16.1905 11.2803 16.0857 11.375L13.0936 14.1172L13.0934 14.1172C12.9961 14.2075 12.8667 14.2553 12.7342 14.2499C12.7185 14.2508 12.7028 14.2508 12.6873 14.2499L12.6875 14.2501Z" fill="inherit" />
                                        </svg>
                                        </span>Learn more
                                    </button>
                                </figcaption>
                            </figure>
                            <figure className="card-hold">
                                <picture className="card-image">
                                    <img src={CosmeticIsland} alt="Body Mind Island" />
                                </picture>
                                <figcaption className="card-text">
                                    <div className="text-hold">
                                        <h4>Cosmetics Island</h4>
                                        <p>Cosmetics Island will be home to skincare routines, aesthetics, makeup tutorials, and all manner of other virtual beauty treatments... </p>
                                    </div>
                                    <button onClick={handleOpen} className="card-icon">
                                        <span className="icon-hold"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11 22C4.93081 22 -4.31065e-07 17.0692 -9.61651e-07 11C-1.49224e-06 4.93081 4.9308 3.39959e-06 11 2.869e-06C17.0692 2.33841e-06 22 4.93081 22 11C22 17.0692 17.0692 22 11 22ZM11 21.0001C16.5286 21.0001 21 16.5287 21 11.0001C21 5.47152 16.5286 1.00009 11 1.00009C5.47143 1.00009 1 5.47152 1 11.0001C1 16.5287 5.47143 21.0001 11 21.0001ZM12.6875 14.2501L12.6875 14.2499C12.4948 14.2238 12.3349 14.0876 12.2779 13.9015C12.2209 13.7152 12.277 13.5128 12.422 13.3829L14.4689 11.5L6.27339 11.5C6.25769 11.5007 6.24217 11.5007 6.22647 11.5C5.95077 11.4869 5.73786 11.2526 5.75076 10.9765C5.76366 10.7003 5.99769 10.487 6.27339 10.4999L14.4685 10.4999L12.4215 8.62488L12.4217 8.62488C12.3233 8.53577 12.2646 8.41109 12.2579 8.27838C12.2515 8.14567 12.2979 8.01575 12.387 7.9174C12.4761 7.81888 12.6006 7.75993 12.7331 7.75348C12.8657 7.74703 12.9952 7.79341 13.0934 7.8827L16.0855 10.6328L16.0857 10.6328C16.1905 10.7275 16.2505 10.8625 16.2505 11.0039C16.2505 11.1453 16.1905 11.2803 16.0857 11.375L13.0936 14.1172L13.0934 14.1172C12.9961 14.2075 12.8667 14.2553 12.7342 14.2499C12.7185 14.2508 12.7028 14.2508 12.6873 14.2499L12.6875 14.2501Z" fill="inherit" />
                                        </svg>
                                        </span>Learn more
                                    </button>
                                </figcaption>
                            </figure>
                            <figure className="card-hold">
                                <picture className="card-image">
                                    <img src={Womenland} alt="Body Mind Island" />
                                </picture>
                                <figcaption className="card-text">
                                    <div className="text-hold">
                                        <h4>Womenland</h4>
                                        <p>Womenland will be home to reproductive health education, pregnancy nutrition, and other female-oriented health topics.</p>
                                    </div>
                                    <button onClick={handleOpen} className="card-icon">
                                        <span className="icon-hold"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11 22C4.93081 22 -4.31065e-07 17.0692 -9.61651e-07 11C-1.49224e-06 4.93081 4.9308 3.39959e-06 11 2.869e-06C17.0692 2.33841e-06 22 4.93081 22 11C22 17.0692 17.0692 22 11 22ZM11 21.0001C16.5286 21.0001 21 16.5287 21 11.0001C21 5.47152 16.5286 1.00009 11 1.00009C5.47143 1.00009 1 5.47152 1 11.0001C1 16.5287 5.47143 21.0001 11 21.0001ZM12.6875 14.2501L12.6875 14.2499C12.4948 14.2238 12.3349 14.0876 12.2779 13.9015C12.2209 13.7152 12.277 13.5128 12.422 13.3829L14.4689 11.5L6.27339 11.5C6.25769 11.5007 6.24217 11.5007 6.22647 11.5C5.95077 11.4869 5.73786 11.2526 5.75076 10.9765C5.76366 10.7003 5.99769 10.487 6.27339 10.4999L14.4685 10.4999L12.4215 8.62488L12.4217 8.62488C12.3233 8.53577 12.2646 8.41109 12.2579 8.27838C12.2515 8.14567 12.2979 8.01575 12.387 7.9174C12.4761 7.81888 12.6006 7.75993 12.7331 7.75348C12.8657 7.74703 12.9952 7.79341 13.0934 7.8827L16.0855 10.6328L16.0857 10.6328C16.1905 10.7275 16.2505 10.8625 16.2505 11.0039C16.2505 11.1453 16.1905 11.2803 16.0857 11.375L13.0936 14.1172L13.0934 14.1172C12.9961 14.2075 12.8667 14.2553 12.7342 14.2499C12.7185 14.2508 12.7028 14.2508 12.6873 14.2499L12.6875 14.2501Z" fill="inherit" />
                                        </svg>
                                        </span>Learn more
                                    </button>
                                </figcaption>
                            </figure>
                            <figure className="card-hold">
                                <picture className="card-image">
                                    <img src={FitnesIsland} alt="Body Mind Island" />
                                </picture>
                                <figcaption className="card-text">
                                    <div className="text-hold">
                                        <h4>Fitness Island</h4>
                                        <p>Fitness Island will be home to all manner of digital gyms, where professionals can provide HIIT training, cycling routines, and personal training...</p>
                                    </div>
                                    <button onClick={handleOpen} className="card-icon">
                                        <span className="icon-hold"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11 22C4.93081 22 -4.31065e-07 17.0692 -9.61651e-07 11C-1.49224e-06 4.93081 4.9308 3.39959e-06 11 2.869e-06C17.0692 2.33841e-06 22 4.93081 22 11C22 17.0692 17.0692 22 11 22ZM11 21.0001C16.5286 21.0001 21 16.5287 21 11.0001C21 5.47152 16.5286 1.00009 11 1.00009C5.47143 1.00009 1 5.47152 1 11.0001C1 16.5287 5.47143 21.0001 11 21.0001ZM12.6875 14.2501L12.6875 14.2499C12.4948 14.2238 12.3349 14.0876 12.2779 13.9015C12.2209 13.7152 12.277 13.5128 12.422 13.3829L14.4689 11.5L6.27339 11.5C6.25769 11.5007 6.24217 11.5007 6.22647 11.5C5.95077 11.4869 5.73786 11.2526 5.75076 10.9765C5.76366 10.7003 5.99769 10.487 6.27339 10.4999L14.4685 10.4999L12.4215 8.62488L12.4217 8.62488C12.3233 8.53577 12.2646 8.41109 12.2579 8.27838C12.2515 8.14567 12.2979 8.01575 12.387 7.9174C12.4761 7.81888 12.6006 7.75993 12.7331 7.75348C12.8657 7.74703 12.9952 7.79341 13.0934 7.8827L16.0855 10.6328L16.0857 10.6328C16.1905 10.7275 16.2505 10.8625 16.2505 11.0039C16.2505 11.1453 16.1905 11.2803 16.0857 11.375L13.0936 14.1172L13.0934 14.1172C12.9961 14.2075 12.8667 14.2553 12.7342 14.2499C12.7185 14.2508 12.7028 14.2508 12.6873 14.2499L12.6875 14.2501Z" fill="inherit" />
                                        </svg>
                                        </span>Learn more
                                    </button>
                                </figcaption>
                            </figure>
                            <figure className="card-hold">
                                <picture className="card-image">
                                    <img src={ArtMusicIsland} alt="Body Mind Island" />
                                </picture>
                                <figcaption className="card-text">
                                    <div className="text-hold">
                                        <h4>Art & Music Island</h4>
                                        <p>A good piece of art or music can change your entire mood, active your mind, and relax even the most stressed-out individual...</p>
                                    </div>
                                    <button onClick={handleOpen} className="card-icon">
                                        <span className="icon-hold"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11 22C4.93081 22 -4.31065e-07 17.0692 -9.61651e-07 11C-1.49224e-06 4.93081 4.9308 3.39959e-06 11 2.869e-06C17.0692 2.33841e-06 22 4.93081 22 11C22 17.0692 17.0692 22 11 22ZM11 21.0001C16.5286 21.0001 21 16.5287 21 11.0001C21 5.47152 16.5286 1.00009 11 1.00009C5.47143 1.00009 1 5.47152 1 11.0001C1 16.5287 5.47143 21.0001 11 21.0001ZM12.6875 14.2501L12.6875 14.2499C12.4948 14.2238 12.3349 14.0876 12.2779 13.9015C12.2209 13.7152 12.277 13.5128 12.422 13.3829L14.4689 11.5L6.27339 11.5C6.25769 11.5007 6.24217 11.5007 6.22647 11.5C5.95077 11.4869 5.73786 11.2526 5.75076 10.9765C5.76366 10.7003 5.99769 10.487 6.27339 10.4999L14.4685 10.4999L12.4215 8.62488L12.4217 8.62488C12.3233 8.53577 12.2646 8.41109 12.2579 8.27838C12.2515 8.14567 12.2979 8.01575 12.387 7.9174C12.4761 7.81888 12.6006 7.75993 12.7331 7.75348C12.8657 7.74703 12.9952 7.79341 13.0934 7.8827L16.0855 10.6328L16.0857 10.6328C16.1905 10.7275 16.2505 10.8625 16.2505 11.0039C16.2505 11.1453 16.1905 11.2803 16.0857 11.375L13.0936 14.1172L13.0934 14.1172C12.9961 14.2075 12.8667 14.2553 12.7342 14.2499C12.7185 14.2508 12.7028 14.2508 12.6873 14.2499L12.6875 14.2501Z" fill="inherit" />
                                        </svg>
                                        </span>Learn more
                                    </button>
                                </figcaption>
                            </figure>
                            <figure className="card-hold">
                                <picture className="card-image">
                                    <img src={NutritionIsland} alt="Body Mind Island" />
                                </picture>
                                <figcaption className="card-text">
                                    <div className="text-hold">
                                        <h4>Nutrition Island</h4>
                                        <p>Nutrition Island will be home to professional dieticians, nutritionists, and fitness coaches, with endless opportunities to provide dietary...</p>
                                    </div>
                                    <button onClick={handleOpen} className="card-icon">
                                        <span className="icon-hold"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11 22C4.93081 22 -4.31065e-07 17.0692 -9.61651e-07 11C-1.49224e-06 4.93081 4.9308 3.39959e-06 11 2.869e-06C17.0692 2.33841e-06 22 4.93081 22 11C22 17.0692 17.0692 22 11 22ZM11 21.0001C16.5286 21.0001 21 16.5287 21 11.0001C21 5.47152 16.5286 1.00009 11 1.00009C5.47143 1.00009 1 5.47152 1 11.0001C1 16.5287 5.47143 21.0001 11 21.0001ZM12.6875 14.2501L12.6875 14.2499C12.4948 14.2238 12.3349 14.0876 12.2779 13.9015C12.2209 13.7152 12.277 13.5128 12.422 13.3829L14.4689 11.5L6.27339 11.5C6.25769 11.5007 6.24217 11.5007 6.22647 11.5C5.95077 11.4869 5.73786 11.2526 5.75076 10.9765C5.76366 10.7003 5.99769 10.487 6.27339 10.4999L14.4685 10.4999L12.4215 8.62488L12.4217 8.62488C12.3233 8.53577 12.2646 8.41109 12.2579 8.27838C12.2515 8.14567 12.2979 8.01575 12.387 7.9174C12.4761 7.81888 12.6006 7.75993 12.7331 7.75348C12.8657 7.74703 12.9952 7.79341 13.0934 7.8827L16.0855 10.6328L16.0857 10.6328C16.1905 10.7275 16.2505 10.8625 16.2505 11.0039C16.2505 11.1453 16.1905 11.2803 16.0857 11.375L13.0936 14.1172L13.0934 14.1172C12.9961 14.2075 12.8667 14.2553 12.7342 14.2499C12.7185 14.2508 12.7028 14.2508 12.6873 14.2499L12.6875 14.2501Z" fill="inherit" />
                                        </svg>
                                        </span>Learn more
                                    </button>
                                </figcaption>
                            </figure>
                            <figure className="card-hold">
                                <picture className="card-image">
                                    <img src={SceneriesLandscapesIslanden} alt="Body Mind Island" />
                                </picture>
                                <figcaption className="card-text">
                                    <div className="text-hold">
                                        <h4>Sceneries & Landscapes Island</h4>
                                        <p>Sceneries & Landscapes Island is dedicated to the world of relaxation. Using visual therapies, you can help improve the minds...</p>
                                    </div>
                                    <button onClick={handleOpen} className="card-icon">
                                        <span className="icon-hold"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11 22C4.93081 22 -4.31065e-07 17.0692 -9.61651e-07 11C-1.49224e-06 4.93081 4.9308 3.39959e-06 11 2.869e-06C17.0692 2.33841e-06 22 4.93081 22 11C22 17.0692 17.0692 22 11 22ZM11 21.0001C16.5286 21.0001 21 16.5287 21 11.0001C21 5.47152 16.5286 1.00009 11 1.00009C5.47143 1.00009 1 5.47152 1 11.0001C1 16.5287 5.47143 21.0001 11 21.0001ZM12.6875 14.2501L12.6875 14.2499C12.4948 14.2238 12.3349 14.0876 12.2779 13.9015C12.2209 13.7152 12.277 13.5128 12.422 13.3829L14.4689 11.5L6.27339 11.5C6.25769 11.5007 6.24217 11.5007 6.22647 11.5C5.95077 11.4869 5.73786 11.2526 5.75076 10.9765C5.76366 10.7003 5.99769 10.487 6.27339 10.4999L14.4685 10.4999L12.4215 8.62488L12.4217 8.62488C12.3233 8.53577 12.2646 8.41109 12.2579 8.27838C12.2515 8.14567 12.2979 8.01575 12.387 7.9174C12.4761 7.81888 12.6006 7.75993 12.7331 7.75348C12.8657 7.74703 12.9952 7.79341 13.0934 7.8827L16.0855 10.6328L16.0857 10.6328C16.1905 10.7275 16.2505 10.8625 16.2505 11.0039C16.2505 11.1453 16.1905 11.2803 16.0857 11.375L13.0936 14.1172L13.0934 14.1172C12.9961 14.2075 12.8667 14.2553 12.7342 14.2499C12.7185 14.2508 12.7028 14.2508 12.6873 14.2499L12.6875 14.2501Z" fill="inherit" />
                                        </svg>
                                        </span>Learn more
                                    </button>
                                </figcaption>
                            </figure>
                            <figure className="card-hold">
                                <picture className="card-image">
                                    <img src={CosmeticIsland} alt="Body Mind Island" />
                                </picture>
                                <figcaption className="card-text">
                                    <div className="text-hold">
                                        <h4>Eduland</h4>
                                        <p>Education is one of the most valuable assets in the real world, and the Metaverse will allow for the transfer of knowledge on a scale we’ve never...</p>
                                    </div>
                                    <button onClick={handleOpen} className="card-icon">
                                        <span className="icon-hold"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11 22C4.93081 22 -4.31065e-07 17.0692 -9.61651e-07 11C-1.49224e-06 4.93081 4.9308 3.39959e-06 11 2.869e-06C17.0692 2.33841e-06 22 4.93081 22 11C22 17.0692 17.0692 22 11 22ZM11 21.0001C16.5286 21.0001 21 16.5287 21 11.0001C21 5.47152 16.5286 1.00009 11 1.00009C5.47143 1.00009 1 5.47152 1 11.0001C1 16.5287 5.47143 21.0001 11 21.0001ZM12.6875 14.2501L12.6875 14.2499C12.4948 14.2238 12.3349 14.0876 12.2779 13.9015C12.2209 13.7152 12.277 13.5128 12.422 13.3829L14.4689 11.5L6.27339 11.5C6.25769 11.5007 6.24217 11.5007 6.22647 11.5C5.95077 11.4869 5.73786 11.2526 5.75076 10.9765C5.76366 10.7003 5.99769 10.487 6.27339 10.4999L14.4685 10.4999L12.4215 8.62488L12.4217 8.62488C12.3233 8.53577 12.2646 8.41109 12.2579 8.27838C12.2515 8.14567 12.2979 8.01575 12.387 7.9174C12.4761 7.81888 12.6006 7.75993 12.7331 7.75348C12.8657 7.74703 12.9952 7.79341 13.0934 7.8827L16.0855 10.6328L16.0857 10.6328C16.1905 10.7275 16.2505 10.8625 16.2505 11.0039C16.2505 11.1453 16.1905 11.2803 16.0857 11.375L13.0936 14.1172L13.0934 14.1172C12.9961 14.2075 12.8667 14.2553 12.7342 14.2499C12.7185 14.2508 12.7028 14.2508 12.6873 14.2499L12.6875 14.2501Z" fill="inherit" />
                                        </svg>
                                        </span>Learn more
                                    </button>
                                </figcaption>
                            </figure>
                        </Slider>
                    </div>
            </section>
            {open ? <ModalBox setOpen={setOpen} open={open} /> : ""}
        </>
    );
}