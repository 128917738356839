import React, { FC } from "react";
import "./modalBox.scss";
import BodyMindIsland from "./../../assets/images/body-world-modal.jpg";
import { Link } from "react-router-dom";

interface IMyProps {
    open: boolean,
    setOpen:any
}
export const ModalBox:FC<IMyProps> = ({open,setOpen}:any) => {
    
    return (
        <>
            <div className="modal modal-overlay">
                <div className="modal-lg">
                    <div className="modal-hold">
                        <div className="image-hold">
                            <picture>
                                <img src={BodyMindIsland} alt="" />
                            </picture>
                        </div>
                        <div className="text-hold">
                            <h2>Body and Mind Island</h2>
                            <p>Body and Mind Island will welcome professionals from backgrounds like Homeopathy, Spirituality, Life Coaching, Psychology, Meditation, Ayurveda, and more.</p>
                            <ul className="land-list">
                                <li>Community Center</li>
                                <li>Beach</li>
                                <li>Forest</li>
                                <li>Hill Area</li>
                                <li>Desert</li>
                                <li>Commercial Area</li>
                                <li>Concert Area</li>
                                <li>Field Area</li>
                            </ul>
                            <div className="btn-group">
                                <Link to="/single-feature" className="btn btn-outline-primary">Explore</Link>
                                <button onClick={()=>setOpen(false)} className="btn btn-primary">Close</button>
                            </div>
                        </div>
                        <span className="cross-icon">
                            <button className="icon-hold" onClick={()=>setOpen(false)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path d="M16 0C7.16917 0 0 7.16955 0 16C0 24.8304 7.16955 32 16 32C24.8305 32 32 24.8304 32 16C32 7.16955 24.8305 0 16 0ZM16 30.2519C8.13082 30.2519 1.74811 23.8692 1.74811 16C1.74811 8.13082 8.13082 1.74811 16 1.74811C23.8692 1.74811 30.2519 8.13082 30.2519 16C30.2519 23.8692 23.8692 30.2519 16 30.2519Z" fill="white"/>
                                    <path d="M21.727 10.2733C21.3773 9.9237 20.8525 9.9237 20.5029 10.2733L16.0003 14.776L11.4976 10.2733C11.148 9.9237 10.6232 9.9237 10.2736 10.2733C9.92394 10.623 9.92394 11.1478 10.2736 11.4974L14.7762 16L10.2736 20.5027C9.92394 20.8523 9.92394 21.3771 10.2736 21.7267C10.4484 21.9016 10.6672 21.9891 10.8856 21.9891C11.1041 21.9891 11.3228 21.9016 11.4976 21.7267L16.0003 17.2241L20.5029 21.7267C20.6777 21.9016 20.8965 21.9891 21.1149 21.9891C21.3334 21.9891 21.5521 21.9016 21.727 21.7267C22.0766 21.3771 22.0766 20.8523 21.727 20.5027L17.2243 16L21.727 11.4974C22.0769 11.1475 22.0769 10.623 21.727 10.2733Z" fill="white"/>
                                </svg>
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};