import React, { useState, FC, useEffect } from "react";
import logo from "../../assets/images/logo.png";
import googleIcon from "../../assets/images/Google.png";
import facebookIcon from "../../assets/images/facebook.png";
import './signUp.scss';
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import { CountryDropdown } from 'react-country-region-selector';

export const SignUp: FC = () => {
    
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [country, setCountry] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [phone, setPhone] = useState("");
    const [errorMessage,setErrorMessage] = useState("");
    const [className,setClassName] = useState("");
    const [isMessage,setIsMessage] = useState(false);
    const [terms,setTerms] = useState(false);
    // validation error messages
    const [nameError, setNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [countryError, setCountryError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [termsError, setTermsError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [validation, setValidation] = useState(false);

    
    function submitForm(){
        let passwordRegex = new RegExp(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/);
        let phoneRegex = new RegExp(/^[0-9\b]+$/);
        if(!name)
        {
            setValidation(true);
            setNameError('Name is required');
        }else{
            if(name.length > 10)
            {
                setValidation(true);
                setNameError('Max 10 characters allowed'); 
            }else{
                setNameError('');
            }
        }
        if(!email)
        {
            setValidation(true);
            setEmailError('Email is required');
        }else{
            if(email.length > 200)
            {
                setValidation(true);
                setEmailError('Max 200 characters allowed'); 
            }else{
                setEmailError('');
            }
        }
        
        if(!password)
        {
            setValidation(true);
            setPasswordError('Password is required')
        }else{
            if(!passwordRegex.test(password))
            {
                setValidation(true);
                setPasswordError('Minimum eight characters, at least one letter, one number and one special character')
            }else{
                // setPasswordError('');
                if(password.length > 200)
                {
                    setValidation(true);
                    setPasswordError('Max 200 characters allowed'); 
                }else{
                    setPasswordError('');
                }
            }
            
        }

        if(phone && !phoneRegex.test(phone)){
            setValidation(true);
            setPhoneError('Only numbers are allowed');
        }else{
            if(phone.length > 200)
            {
                setValidation(true);
                setPhoneError('Max 200 characters allowed'); 
            }else{
                setPhoneError('');
            }
        }

        if(!country)
        {
            setValidation(true);
            setCountryError('Country is required');
        }else{
            setCountryError('');
        }
        
        if(!terms)
        {
            setValidation(true);
            setTermsError('Please read and accept the terms and conditions to proceed');
        }else{
            setTermsError('');
        }

        if(password != confirmPassword)
        {
            setValidation(true);
            setConfirmPasswordError('Confirm Password and Password must match');
        }else{
            setConfirmPasswordError('');
        }

            if(!terms || !name || !email || !password || password != confirmPassword || !passwordRegex.test(password) || !country || password.length > 200 || phone.length > 200 || email.length > 200 || name.length > 10 || !phoneRegex.test(phone) )
            {
                // setValidation(false);
                setClassName('error-text-color');
                return false;
            }else{
        axios.post(process.env.REACT_APP_BACKE_END_URL+'/api/users/register', {
            name: name,
            email: email,
            password: password,
            confirmPassword: confirmPassword,
            phone: phone,
            country: country
          })
          .then(function (response) {
            setErrorMessage(response.data.message);
            setClassName('success-text-color');
            setIsMessage(true);
            setTimeout(() => {
                navigate("/login");
            }, 3000);
          })
          .catch(function (error) {
              setErrorMessage(error.response.data.message);
              setClassName('error-text-color');
              setIsMessage(true);

          });
        }
      }

    useEffect(() => {
        window.scrollTo(0, 0);
        document.getElementById('root')?.classList.add('none');
        return () => {
            document.getElementById('root')?.classList.remove('none');   
        }
    }, [])
    return (
        <>
            <div className="login-area flex">
                <div className="left-col relative">
                    <div className="main-content">
                        <Link to="/"> 
                            <img src={logo} alt="#" />
                        </Link>
                        <div className="inner-content flex">
                            <h1>Glad to see you!</h1>
                            <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.</p>
                        </div>
                    </div>
                </div>
                <div className="right-col">
                    <Link to="/login" className="btn btn-outline-primary flex ml-auto w-auto">Login</Link>
                    <div className="right-content signUp">
                        <h2>Sign Up</h2>
                        {isMessage?<div  className={className}>{errorMessage}</div>:''}
                        <form>
                            <div className="row flex fields">
                                <div className="col">
                                    <label htmlFor="name">Name <em className="required-field">*</em></label>
                                    <input
                                        id="name"
                                        type="text"
                                        placeholder="Full Name"
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    {validation?<div  className={className}>{nameError}</div>:''}
                                </div>
                                <div className="col ml-auto">
                                    <label htmlFor="email">Email <em className="required-field">*</em></label>
                                    <input
                                        id="email"
                                        type="email"
                                        placeholder="Email Address"
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    {validation?<div  className={className}>{emailError}</div>:''}
                                </div>
                            </div>
                            <div className="row flex fields">
                                <div className="col relative">
                                    <label htmlFor="password">Password</label>
                                    <input
                                        id="password"
                                        type="password"
                                        placeholder="Password"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    
                                  
                                    {validation?<div  className={className}>{passwordError}</div>:''}
                                 
                                </div>
                                <div className="col ml-auto relative">
                                    <label htmlFor="confirm-password">Confirm Password</label>
                                    <input
                                        id="confirm-password"
                                        type="password"
                                        placeholder="Confirm Password"
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                    {validation?<div  className={className}>{confirmPasswordError}</div>:''}
                                </div>
                            </div>
                            <div className="row flex fields">
                                <div className="col">
                                    <label htmlFor="name">Country of Residence</label>
                                    <CountryDropdown
                                    value={country}
                                    classes ="select-country"
                                    onChange={(val) => setCountry(val)} />
                                      {validation?<div  className={className}>{countryError}</div>:''}
                                </div>
                                <div className="col ml-auto">
                                    <label htmlFor="phone">Phone Number</label>
                                    <input
                                        id="phone"
                                        type="text"
                                        placeholder="Number"
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                    {validation?<div  className={className}>{phoneError}</div>:''}
                                </div>
                            </div>
                            <div className="row">
                            <div className="accept-row flex items-center">
                                <input type="checkbox"  onChange={() => setTerms(!terms)} id="accept-terms" className="hidden" />
                                <label htmlFor="accept-terms" className="fancy-checkbox relative">
                                    Yes, I have read and I do agree with Toggl Hire's Terms & Conditions
                                </label>
                            </div>
                            {validation?<div  className={className}>{termsError}</div>:''}
                            </div>
                            <button type="button" onClick = {() => submitForm()} className="btn-login flex items-center flex-text-center">Sign Up</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}