import React, { FC } from "react";
import { Link } from "react-router-dom";
import "./featureBanner.scss";

export const FeatureBanner:FC = () => {
    return (
        <>
        <section className="feature-banner text-center">
                <div className="container">
                    <div className="feature-banner-hold">
                    <h1>
                        Metavitality Features
                    </h1>
                    <header className="heading-head">
                        <strong className="h3 d-block">Own Virtual Land. Grow a Digital Business. Earn Real Income.</strong>
                        <p>Metavitality is, above all, a world of opportunity. As this world grows, scales, and expands, it will present business owners, virtual landlords, and digital clients with endless new ways to change lives. Reach a global clientele, run and build your business, or buy and sell virtual land and services. The possibilities are endless. The freedom to innovate is infinite.</p>
                    </header>
                        <Link to="/" className="btn btn-outline-primary">Join Today</Link>
                    </div>
                </div>
            </section>
        </>
    );
};