import React, { useState,FC } from "react";
import "./forgotPassword.scss";
import crossIcon from "../../assets/images/cross-icon.png";
import {Link} from "react-router-dom";
import axios from "axios";
export const ForgotPassword:FC = () => {
    const [email, setEmail] = useState("");
    const [message,setMessage] = useState("");
    const [className,setClassName] = useState("");
    const [isMessage,setIsMessage] = useState(false);

    function submitForm(){
        axios.post(process.env.REACT_APP_BACKE_END_URL+'/api/users/forget-password', {
            email: email,
          })
          .then(function (response) {
            setMessage(response.data.message);
            setClassName('success-text-color');
            setIsMessage(true);
          })
          .catch(function (error) {
              setMessage(error.response.data.message);
        setClassName('error-text-color');
        setIsMessage(true);
          });
      }
    return (
        <>
            <div className="popup flex items-center flex-text-center active">
                <div className="popup-holder relative">
                    <h2>Forgot Password</h2>
                    <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.</p>
                    <div className="row">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email"  onChange={(e) => setEmail(e.target.value)} placeholder="Enter your Email"/>
                    </div>
                    {isMessage?<div  className={className}>{message}</div>:''}
                    <Link to="#" onClick = {() => submitForm()} className="btn btn-primary flex w-auto">Reset Password</Link>
                    
                    <a href="/login" ><span className="cross-icon">
                        <img src={crossIcon} alt="cross-icon"/>
                    </span>
                    </a>
                </div>
            </div>
        </>
    );
};