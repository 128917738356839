import React, { FC, useState } from "react";
import "./buildWorld.scss";
import GlobeImage from "./../../../assets/images/globe.png";
import { ModalBox } from "../../../component/modal-box/ModalBox";

export const BuildWorld:FC = () => {
    const [open,setOpen] = useState<boolean>(false)
    const handleOpen = () => {
        setOpen(true)
    }
    return (
        <>
            <section className="build-world">
                <div className="container">
                    <div className="build-world-hold">
                        <div className="image-hold">
                            <picture className="relative">
                                <img src={GlobeImage} alt="Build World"/>
                                <span onClick={handleOpen} className="tooltip body-mind inline-block absolute">
                                    <strong className="d-block">Body & Mind Island</strong>
                                    <p>Available on metavitality</p>
                                </span>
                                <span onClick={handleOpen} className="tooltip cosmetic inline-block absolute">
                                    <strong className="d-block">Cosmetics Island</strong>
                                    <p>Available on metavitality</p>
                                </span>
                                <span onClick={handleOpen} className="tooltip fitness inline-block absolute">
                                    <strong className="d-block">Fitness Island</strong>
                                    <p>Available on metavitality</p>
                                </span>
                            </picture>
                        </div>
                        <div className="text-hold">
                            <h2>Build Worlds. Change Lives.</h2>
                            <p>
                                <strong className="inline-block">Welcome to MetaVitality, </strong>
                                 where you can join the future of health
                                 and fitness. This assortment of dedicated virtual islands is poised to
                                 revolutionize the industry while redefining what it means to be part of a
                                 healthcare community. Best of all, you can help build a world that fosters
                                 true equality by utilizing digital space and the Ethereum blockchain.
                            </p>
                            <ul>
                                <li>
                                    <strong className="h4 d-block">Purchase Virtual Land:</strong>
                                    <p>8 unique Islands focused on different aspects of health, fitness, and nutrition.</p>
                                </li>
                                <li>
                                    <strong className="h4 d-block">Generate Income:</strong>
                                    <p>Invest in plots with massive potential to increase in value.</p>
                                </li>
                                <li>
                                    <strong className="h4 d-block">100% Virtual Real Estate:</strong>
                                    <p>Create new infrastructure using Terra Virtua’s advanced technology.</p>
                                </li>
                                <li>
                                    <strong className="h4 d-block">Reach a Global Clientele:</strong>
                                    <p>A comprehensive search engine pairs users with healthcare professionals.</p>
                                </li>
                                <li>
                                    <strong className="h4 d-block">Run Your Business:</strong>
                                    <p>Enhanced scheduling helps you make and manage appointments.</p>
                                </li>
                                <li>
                                    <strong className="h4 d-block">Grow Beyond Your Wildest Dreams:</strong>
                                    <p>Enjoy Infinite scalability with endless chances to innovate. </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {open? <ModalBox setOpen ={setOpen} open={open}/>:""}
        </>
    );
};