import React, { useState, FC, useEffect } from "react";
import logo from "../../assets/images/logo.png";
import './login.scss';
import {Link, useNavigate} from "react-router-dom";
import eyeClose from "../../assets/images/hideeye.png";
import axios from "axios";
export const Login: FC = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [validation, setValidation] = useState(false);
    const [password, setPassword] = useState("");
    const [user,setUser] = useState({});
    const [error,setError] = useState({});
    const [token,setToken] = useState("");
    const [message,setMessage] = useState("");
    const [className,setClassName] = useState("");
    const [isMessage,setIsMessage] = useState(false);
    const [rememberMe,setRememberMe] = useState(false);

    function submitForm(){
    if(!email)
    {
        setEmailError('Email is required');
    }else{
        setEmailError('');
    }
    if(!password)
    {
        setPasswordError('Password is required');
    }else{
        setPasswordError('');
    }
    if(!email || !password){
        setClassName('error-text-color');
        setValidation(true);
        return false;
    }else{
        setClassName('');
        setEmailError('');
        setPasswordError('');
        setValidation(false);
    }
    if(rememberMe){
        sessionStorage.setItem("email", email);
        sessionStorage.setItem("password", password);
    }
   
    axios.post(process.env.REACT_APP_BACKE_END_URL+'/api/users/authenticate', {
        email: email,
        password: password
      })
      .then(function (response) {
      
          if(response.data.status == 201){   
            setMessage('Check your email to verify your account');
            setClassName('error-text-color');
            setIsMessage(true);
          }else{

        
        setUser(response.data.data);
        sessionStorage.setItem("token", JSON.stringify(response.data.data.token));

        setMessage(response.data.message);
        setClassName('success-text-color');
        setIsMessage(true);

        // timer to redirect after 3 seconds
       
             setTimeout(() => {
                navigate("/");
            }, 3000);
        }
        // localStorage.getItem('items')
      })
      .catch(function (error) {
        setMessage(error.response.data.message);
        setClassName('error-text-color');
        setIsMessage(true);
      });
  }
  
    useEffect(() => {
 
        window.scrollTo(0, 0);
        document.getElementById('root')?.classList.add('none');
        
        return () => {
            document.getElementById('root')?.classList.remove('none');   
        }
    }, [])
    return (
        <>
            <div className="login-area flex">
                <div className="left-col relative">
                    <div className="main-content">
                        <Link to="/">
                            <img src={logo} alt="#" />
                        </Link>
                    <div className="inner-content flex">
                    <h1>Welcome Back!</h1>
                    <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.</p>
                    </div>
                    </div>
                </div>
                <div className="right-col">
                    <Link to="/signup" className="btn btn-primary flex ml-auto w-auto">Sign up</Link>
                    <div className="right-content">
                        <h2>Login</h2>
                        <p>Nemo enim ipsam voluptatem quia</p>
                        <form>
                        <div className="row">
                            <label htmlFor="email">Email <em className="required-field">*</em></label>
                            <input
                                id="email"
                                type="email"
                                placeholder="Email Address"
                                onChange={(e) => setEmail(e.target.value)}
                                defaultValue={sessionStorage.getItem("email") || ""}
                            />
                            {validation?<div  className={className}>{emailError}</div>:''}
                        </div>
                        <div className="row relative">
                            <label htmlFor="password">Password</label>
                            <input
                                id="password"
                                type="password"
                                placeholder="Enter Password"
                                onChange={(e) => setPassword(e.target.value)}
                                defaultValue={sessionStorage.getItem("password") || ""} 
                            />
                            
                            <span className="icon-eye">
                                    <img src={eyeClose} alt="eye"/>
                                </span>
                            {validation?<div  className={className}>{passwordError}</div>:''}
                            {isMessage?<div  className={className}>{message}</div>:''}
                        </div>
                            <div className="row flex items-center">
                            <div className="accept-row flex items-center">
                                <input type="checkbox"  id="remember-me" className="hidden" />
                                <label htmlFor="remember-me" className="fancy-checkbox relative">
                                    Remember me
                                </label>
                            </div>
                                <Link to="/forgot-password" className="ml-auto">Forgot Password?</Link>
                            </div>
                            <button type="button" aria-label="login" onClick = {() => submitForm()} className="btn-login flex items-center flex-text-center">Login</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}